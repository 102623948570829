import { ZigThemeOverrides } from "./types";
import dark from "./themes/dark";
import light from "./themes/light";
import { getMuiTheme } from "./muiTheme";
import { Theme } from "@mui/system";

export const zignalyBaseThemeConfig = dark;

export const getZignalyThemeExport = (
  themeName?: "dark" | "light",
  overrides?: ZigThemeOverrides | ZigThemeOverrides[],
): Theme => {
  const theme = themeName === "light" ? light : dark;
  return getMuiTheme(theme, overrides ? (Array.isArray(overrides) ? overrides : [overrides]) : []);
};

export { GlobalAppStyle } from "./GlobalAppStyle";
